.edit-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
}

.edit-popup__inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 900px;
    background-color: #a5ccd1;
    border: 2px solid var(--alert-color);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.edit-confirm,
.edit-cancel {
    padding: 20px;
    margin: 10px;
}