:root {
    --project-color: #D6C9C9;
    --task-color: #D7DEDC;
    --alert-color: #BD3B45;
}

*,
*::before,
*::after {
    font-family: inherit;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: "Work Sans", sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
    color: #2B2D42;
}

