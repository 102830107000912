.delete-btn {
    margin-top: 50px;
    height: 50px;
    font-size: 1.0rem;
    color: var(--alert-color);
    border: 1px solid var(--alert-color);
    background: transparent;
    padding: 0 15px;
    cursor: pointer;
    transition: border 250ms ease-in;
}

.delete-btn:hover {
    border: 3px solid var(--alert-color);
}