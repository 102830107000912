.error-page {
    height: 100vh;
    font-size: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-shadow: 1px 2px 3px var(--project-color);
    background-color: var(--project-color);
}