.projects-list {
    font-size: 1.2rem;
    line-height: 1.7;
    list-style: circle;
    padding-left: 1.1em;
    cursor: pointer;
}

.projects-list--active {
    font-weight: 700;
}