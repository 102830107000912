.add-project {
    margin-top: 20px;
    display: flex;
}

.add-project__btn {
    cursor: pointer;
    background: 0;
    border: 0;
    padding: 0;
    color: inherit;
    font-size: 1.5rem;
    font-weight: 900;
    margin-right: 0.25em;
    transition: opacity 250ms ease-in;
}

.add-project__btn:hover {
    opacity: 0.7;
}

.add-project__list {
    background: transparent;
    border: 0;
    color: inherit;
    border-bottom: 1px solid currentColor;
    outline: none;
    font-size: 1.1rem;

    transition: border-bottom 150ms ease-in;
    order: 2;
}

.add-project__list:focus {
    border-bottom-width: 3px;
}

.add-project__list:focus::placeholder {
    opacity: 0.2;
}